import { yupResolver } from '@hookform/resolvers/yup';
import { useAppMessage } from '@one-snap/next';
import { Button, TextField, useReactlytics } from '@one-snap/sparkles';
import { useSendOTPCommand } from 'lib/models/settings/command/useSendOTPCommand';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import { FormSchemaData, formSchema } from './schema';
import { useRouter } from 'next/router';
import { routes } from 'utilities/routing';
import { useUserInput } from 'state/hooks';
import { useEffect } from 'react';
import { ErrorType } from 'lib/models/settings/command/types';
import { FieldControl } from '@one-snap/forms';

export function LoginForm() {
  const { tracking } = useReactlytics();
  const router = useRouter();
  const sendOTPCommand = useSendOTPCommand();
  const { showErrorMessage } = useAppMessage();
  const t = useTranslations();
  const { persistUserInputs } = useUserInput();
  const { handleSubmit, control, setValue } = useForm<FormSchemaData>({
    defaultValues: {
      email: ''
    },
    mode: 'all',
    resolver: yupResolver(formSchema)
  });
  const email = router.query?.email as string;
  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, []);

  const trackOTPErrors = (event, errMsg) => {
    tracking.logSystemEvent('error', {
      event: `send otp ${event}`,
      displayMessage: errMsg
    });
  };

  const handleErrors = (sendOTPReturn) => {
    let errMsg = '';

    if (sendOTPReturn?.errors?.includes(ErrorType.OTP_SEND_ACCOUNT_BLOCKED)) {
      errMsg = t('shared.forms.errors.acountBlocked');
      trackOTPErrors('account blocked', errMsg);
    } else if (sendOTPReturn?.errors?.includes(ErrorType.IP_ADDRESS_BLOCKED)) {
      errMsg = t('shared.forms.errors.ipBlocked');
      trackOTPErrors('IP address blocked', errMsg);
    } else {
      errMsg = t('shared.forms.errors.otpSendError');
      trackOTPErrors(
        `API failure: ${JSON.stringify(sendOTPReturn?.errors)}`,
        errMsg
      );
    }
    return showErrorMessage({
      message: errMsg
    });
  };

  const onSubmitHandler = async (data: FormSchemaData) => {
    tracking.logProcessEvent('email submitted for otp', {});
    persistUserInputs({ email: data.email });
    const sendOTPReturn = await sendOTPCommand.execute({ email: data.email });
    if (sendOTPReturn.success && sendOTPReturn?.errors?.length <= 0) {
      return router.push(routes.otp());
    } else {
      return handleErrors(sendOTPReturn);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="mt-5">
        <FieldControl
          className="w-full"
          name="email"
          control={control}
          render={(field, invalid) => (
            <TextField
              {...field}
              fullWidth
              label={t('login.email')}
              error={invalid}
              data-testid="email"
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Button
          fullWidth
          type="submit"
          loading={sendOTPCommand.executing}
          disabled={sendOTPCommand.executing}
          variant="primary"
          reactlytics={{
            clickType: 'CTA',
            clickValue: t('shared.continue'),
            placement: 'body'
          }}
        >
          {t('shared.continue')}
        </Button>
      </div>
    </form>
  );
}
